import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from "@angular/forms";
import * as components from "./components";
import * as directives from "./directives";
import * as base from "./base";
import {CommonFilterService, CommonService, DocumentBaseService} from "@amlDocument/services";
import {CoreModule} from "../../core";
import {NgSelectModule} from "@ng-select/ng-select";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {NgJsonEditorModule} from "ang-jsoneditor";
import {NgxMaskModule} from "ngx-mask";
import {UiSwitchModule} from "ngx-ui-switch";
import {DictionariesModule} from "../../dictionaries";

const declarationsAndExports = [
  // base
  base.DocumentFormComponent,
  // tables form
  components.TableOsnovSovOperComponent,
  components.TableClientInfoComponent,
  components.TableDopClientInfoComponent,
  components.TableInfoCreditOrgComponent,
  components.TableTrastInfoComponent,
  components.TableSvedOperComponent,
  components.TableMainObstructionFactComponent,
  components.TableLegalRequirementsObstructionFactComponent,
  components.TableNormativeActObstructionFactComponent,
  components.TableParagraphObstructionFactComponent,
  // views form (их можно не экспортить в принципе, большая часть это чисто компонент таблицы)
  components.ViewClientInfoComponent,
  components.ViewDopClientInfoComponent,
  components.ViewOsnovSovOperComponent,
  components.ViewInfoFlComponent,
  components.ViewInfoUlComponent,
  components.ViewInfoInboiulComponent,
  components.ViewTrastInfoComponent,
  components.ViewSvedOperComponent,
  components.ViewMainObstructionFactComponent,
  components.InfoCreditOrgUsingBankAccountComponent,
  components.ViewLegalRequirementsObstructionFactComponent,
  components.ViewNormativeActObstructionFactComponent,
  components.ViewParagraphObstructionFactComponent,
  // common views
  components.AddressComponent,
  components.PassportComponent,
  components.PassportNrComponent,
  components.InfoAuthPersonOrgComponent,
  components.InfoCreditOrgToAuthBodyComponent,
  components.ViewInfoCreditOrgComponent,
  components.ViewFioComponent,
  // common
  components.CustomSelectComponentForFm, // todo depracated
  components.AccordionFormGroupComponent,
  components.ServicePartComponent,
  components.ImportDocumentsComponent,
  components.NeedAttentionComponent,
  components.CommonSendDocumentsComponent,
  components.CommonCheckDocumentsComponent,
  components.ImportDocumentsV2Component,
  components.CommonServiceInformationComponent,
  components.CreateCorrectReplaceRemoveDocumentsComponent,
  components.CommonSignAllDocsComponent,
  components.TableSvedTspComponent,
  components.ViewSvedTspComponent,
  components.InfoEmitentComponent,
  components.CorrectReplaceMessageComponent,
  components.PlaceBirthOperationsComponent,
  // multiple
  components.MultipleSvedDragMetalComponent,
  components.MultipleIdentInstructComponent,
  components.MultipleMeraOsnovanieComponent,
  // directives
  directives.ShowFormGroupDirective,
  directives.ShowFormControlDirective,
  directives.ChangeValidationFormControlDirective,
  // errors modal on save doc
  components.SaveErrorModalComponent,
  // lists
  components.DeletedListComponent,
  components.ArchiveListComponent,
  // liteComponents
  components.CommonMaxLengthErrorComponent,
  // download files
  components.DownLoadFilesComponent,
];

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgbModule,
    NgJsonEditorModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(),
    UiSwitchModule.forRoot({
      size: 'medium',
      color: '#ae0000'
    }),
    DictionariesModule,
    CoreModule
  ],
  declarations: [...declarationsAndExports],
  exports: [...declarationsAndExports],
  providers: [DocumentBaseService, CommonService, CommonFilterService]
})
export class DocumentFormModule { }
