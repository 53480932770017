import { Component, Input, OnInit, forwardRef } from "@angular/core";
import { DictionaryService, StringDateAdapterService, TableService } from "@amlCore/services";
import { FormBuilder, NG_VALUE_ACCESSOR } from "@angular/forms";
import { FmDraftFilter } from "../../models";
import { ActivatedRoute } from "@angular/router";
import { BaseFilterController } from "../BaseFilterController";
import { DocTypeSearch, getDocStatuses, getDocTypes, getFilterDesc } from "../../constants/docTypes.constants";
import { CommonArchiveFilter } from "../../models/commonArchiveFilter.model";

@Component({
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CommonArchiveFilterComponent),
            multi: true,
        },
    ],
    selector: "app-common-archive-filter",
    templateUrl: "./commonArchiveFilter.component.html",
})
export class CommonArchiveFilterComponent extends BaseFilterController implements OnInit {
    @Input() urlData;
    @Input() groupName: string;

    filterObjectModel = new CommonArchiveFilter();
    placeholder: string;
    docStatuses: DocTypeSearch[];
    docTypes: DocTypeSearch[];
    constructor(
        private readonly fb: FormBuilder,
        protected tableSrv: TableService,
        private dictSrv: DictionaryService,
        public dateSrv: StringDateAdapterService,
        protected readonly activatedRoute: ActivatedRoute
    ) {
        super(tableSrv, activatedRoute);
    }

    ngOnInit() {
        this.init();
        this._setFilterConfig();
    }

    /**
     * Метод создания формы
     * @return FormGroup
     */
    public createFormModel() {
        const formConfig = new CommonArchiveFilter();
        return this.fb.group(formConfig);
    }

    getStartFilterModel(): CommonArchiveFilter {
        return {
            documentType: null,
        } as CommonArchiveFilter;
    }

    /**
     * Описания полей
     */
    public getFilterDescription(): any {
        return getFilterDesc(this.groupName + "Archive");
    }

    private _setFilterConfig() {
        switch (this.groupName) {
            case "strateg655p":
                this.placeholder = "Поиск по идентификатору документа, имени xml-документа";
                break;
            case "elMsgFm":
                this.placeholder = "по номеру запроса РФМ (значение НомЗапр из xml), имени xml-документа";
                break;
            case "strateg5392u":
                this.placeholder = "Поиск по идентификатору документа (значение ИдентифИнфКО из xml), имени xml-документа";
                break;
            case "fm":
                this.placeholder = "Поиск по идентификатору документа, по идентификатору записи (тег НомерЗаписи в xml),  имени xml-документа";
                break;
            case "control6670":
                this.placeholder = "Поиск по идентификатору документа, по идентификатору записи (тег НомерЗаписи в xml),  имени xml-документа";
                break;
            default:
                break;
        }
        this.docTypes = getDocTypes(this.groupName);
        this.docStatuses = getDocStatuses(this.groupName + "Archive");
    }

    get users() {
        return this.dictSrv?.remoteDict[this.groupName]?.list || [];
    }

    get textLabel() {
        return "Дата сообщения с";
    }
    isNeedIdRecord() {
        return ['fm', 'control6670'].includes(this.groupName);
    }

    isNeedDocType() {
        return ['fm', 'strateg655p', 'elMsgFm', 'strateg5392u'].includes(this.groupName);
    }
    isNeedContainerName() {
        return ['fm', 'strateg655p', 'control6670'].includes(this.groupName);
    }
}
