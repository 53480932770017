import {Component, forwardRef} from '@angular/core';
import {DictionariesService} from "../../../service";
import {QrefOkwedModel} from "../../../models";
import {NG_VALUE_ACCESSOR} from "@angular/forms";
import {ViewDictionaryBase} from "../view-dictionary-base";
import {PageOkwed} from "../../../../../../api/build";

@Component({
  selector: 'app-dict-qref-okwed',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => QrefOkwedViewComponent),
    multi: true
  }],
  template: `
    <div class="mb-3 col-3 pl-0 pr-0">
      <label class="form-label">{{title}}
        <app-rq *ngIf="isFieldReq(formControl)" [isReadOnly]="isDisabled"></app-rq>
      </label>
      <app-custom-select *ngIf="dictionary | async as dict; else loading"
                         code="kod"
                         [items]="dict"
                         [returnOnlyId]="true"
                         [formControl]="formControl"
                         [fieldsFromSearch]="['name']"
                         [isInvalid]="submitted && formControl.errors">
        <ng-template let-item #labelTemplate>
          {{ item.name }}
        </ng-template>
        <ng-template let-item let-search="search" #optionTemplate>
          {{ item.name }}
        </ng-template>
      </app-custom-select>
      <ng-template #loading>
        <app-skeleton></app-skeleton>
      </ng-template>
      <app-valid [logic]="formControl" [submitted]="submitted"></app-valid>
    </div>
  `
})
export class QrefOkwedViewComponent extends ViewDictionaryBase<PageOkwed> {
  getDictionary = this.dictSrv.getQrefOkwedList();
  constructor(private dictSrv: DictionariesService) {
    super();
  }
}
