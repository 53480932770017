import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {IconsService} from "@amlCore/services";
import {ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs';
import { CommonService, DocumentBaseService } from '../../../../documentForm/services';
import { getDocumentConfig, DocumentConfigService, DocumentTypeEnum } from '../../../../documentForm/enums';


@Component({
  selector: 'app-common-service-information',
  templateUrl: './common-service-information.component.html',
  styleUrls: ['../../../../documentForm/base/documentForm.component.scss']
})
export class CommonServiceInformationComponent implements OnInit, OnDestroy {
  @Input() data: any = {};
  @Input() docType: DocumentTypeEnum = null;
  @Input() id: string = null;
  @Input() receiptUrl: string = '/api/v1/documents/sended/receipt/download/';

  configuration: DocumentConfigService = null;
  listSign = [];
  linkDocFileUrl = ''; // урл для получения xml документа
  linkSignFileUrl = ''; // урл для получения подписи документа
  linkDocContainerUrl = ''; // урл для получения контейнера документа
  linkDocReceiptUrl = ''; // урл для получения квитанции документа
  linkDocReceiptSignUrl = ''; // урл для получения подписи квитанции документа
  nameSign = ''; // урл для получения xml документа
  showAttach = true;
  showSign = true;
  showTicket = true;
  showAttributes = true;
  sRouteParams: Subscription;
  svgIcons = this._iconsSrv.getSvgIcons();

  constructor(private _iconsSrv: IconsService,
              private commonSrv: CommonService,
              private activateRoute: ActivatedRoute,
              public docSrv: DocumentBaseService) {
  }

  ngOnInit(): void {
    this.configuration = getDocumentConfig(this.docType)
    this.linkDocFileUrl = this.configuration.api + 'downloadDoc/' + this.id;
    if (this.data.container) {
      this.linkDocContainerUrl = '/api/v1/documents/container/download/' + this.data.container.id;
    }
    if (this.data.signId) {
      this.linkSignFileUrl = this.configuration.api + 'download/' + this.data.signId;
      this.nameSign = this.data.documentName + '.sign'
    }

    if(this.data.receipt){
      this.linkDocReceiptUrl = this.receiptUrl + this.id;
    }

    if(this.data.receiptSign){
      this.linkDocReceiptSignUrl = '/api/v1/documents/sended/receipt/sign/download/' + this.id;
    }
  }

  ngOnDestroy(): void {
    if(this.sRouteParams) {
      this.sRouteParams.unsubscribe();
    }
  }

  download() {
    setTimeout(() => {
      this.sRouteParams = this.activateRoute.queryParams.subscribe(params => {
        if (params.page === 'sendssended') {
          this.commonSrv.getDocInfoById(this.id, this.docType).subscribe(data => {
            this.data.downloadDate = data.downloadDate
          })
        }
      });
    }, 2000);
  }

  getData(path: string) {
    return this.data[path] ?? null;
  }
}
