import {FormBuilder, FormControl, FormGroup, ValidatorFn} from "@angular/forms";
import {ValidatorService} from "@amlCore/services";
import {ValidatorParamsModel} from "@amlCore/models";
import {FormArrayGetterFn, FormCntrlGetterFn, FormGroupGetterFn} from "../types";
import {Utils} from "@amlCore/utils";
import {DocumentTypeEnum, REFUSE_CONTRACT_1_X, REFUSE_OPERATION_1_X} from "../enums";

export abstract class DocumentBaseForm {
  protected constructor(protected fb: FormBuilder,
                        protected validationSrv: ValidatorService) {}

  public valid = (param: ValidatorParamsModel | string): ValidatorFn[] =>
    this.validationSrv.getValidation(param)

  public fbControl: FormCntrlGetterFn = (value, valid) => {
    const control = this.fb.control(value, valid);
    if (!value) control.disable({ onlySelf: true });
    return control;
  }

  protected fbGroup = (data, controls: { [key: string]: any }, valid?: ValidatorFn[]): FormGroup => {
    const group = this.fb.group(controls, valid);
    if (!data) { group.disable({ onlySelf: true }); }
    return group;
  }

  protected fbArray: FormArrayGetterFn = (fn, data, valid?, parentValid?, doctype?, otherParam?) => {
    return !data || data.length === 0
      ? this.fb.array([], parentValid)
      : this.fb.array(data.map(item => fn.apply(this, [item, valid, doctype, otherParam])), parentValid)
  }
  /**
   * Функция для выключения определенных formControl'ов.
   * Нужно для импортированных документов, в которых может возникать проблемы в условных полях
   * @param form - форма, в которой нужно вносить изменения
   * @param condition - условие, при котором будут отключаться поля
   * @param controlsNames - объект с массивами formControl'ов
   */
  protected _disableFormControls(form: FormGroup, condition: boolean, controlsNames: {firstArrayControlsNames, secondArrayControlsNames}) {
    condition
      ? (
        controlsNames.secondArrayControlsNames.forEach(
          controlName => form.get(controlName).disable({ onlySelf: true }))
      )
      : (
        controlsNames.firstArrayControlsNames.forEach(
          controlName => form.get(controlName).disable({ onlySelf: true }))
      )
  }

  /*--------------FM------------*/
  getInitialDocumentFormFm: FormGroupGetterFn = (data, valid?, docType?) => {
    return this.fb.group({
      informChast: this.getInformChast(data?.informChast),
      sluzhChast: this.getSluzhChast(data?.sluzhChast, [], docType)
    }, valid);
  }

  getInformChast: FormGroupGetterFn = (data, valid?) => {
    return this.fb.group({
      infBank: this.getInfBank(data?.infBank)
    }, valid);
  }

  getInfBank: FormGroupGetterFn = (data, valid?) => {
    return this.fb.group({
      bikko: [data?.bikko, this.valid({ isReq: true, min: 9, max: 9 })],
      nomFlPeredaiushchij: [data?.nomFlPeredaiushchij, this.valid({ max: 4 })],
      okatoko: [data?.okatoko, this.valid({ isReq: true, min: 2, max: 2 })],
      regNomKO: [data?.regNomKO, this.valid({ isReq: true, max: 4 })],
    }, valid);
  }

  getSluzhChast: FormGroupGetterFn = (data, valid?, docType?) => {
    const isRefuse = [...REFUSE_OPERATION_1_X, ...REFUSE_CONTRACT_1_X,
      DocumentTypeEnum.REFUSE_OPERATION, DocumentTypeEnum.REFUSE_CONTRACT].includes(docType);
    const is5392 = docType === DocumentTypeEnum.OES_OTKAZ_GOZ;

    return this.fb.group({
      dataSoobshcheniia: [data?.dataSoobshcheniia, this.valid({ isReq: true, isDate: true })],
      elektronnaiaPochta: [data?.elektronnaiaPochta, this.valid({ max: 250, pattern: Utils.emailPattern })],
      fioupolnSotrudn: this.getFio(data?.fioupolnSotrudn),
      idkorr: [data?.idkorr, this.valid({ isReq: true, max: 40 })],
      telUpolnSotrudn: [data?.telUpolnSotrudn, this.valid({ isReq: true, max: 250 })],
      tipInf: [data?.tipInf, this.valid({ isReq: true, max: isRefuse ? 2 : is5392 ? 6 : 1 })],
      upolnSotrudn: [data?.upolnSotrudn, this.valid({ isReq: true, max: 250 })],
      versProg: [data?.versProg, this.valid({ isReq: true, max: 50 })],
      versiiaFormata: [data?.versiiaFormata, this.valid({ isReq: true, max: 10 })]
    }, valid);
  }

  /*--------------ARM4937------------*/
  getInitialDocumentFormArm4937: FormGroupGetterFn = (data, valid?) => {
    return this.fb.group({
      informChast: this.getInformChastInfNFOP(data.informChast),
      sluzhChast: this.getSluzhChast(data.sluzhChast),
    }, valid);
  }

  private getInformChastInfNFOP: FormGroupGetterFn = (data, valid?) => {
    return this.fb.group({
      infNFOP: this.getInfNFOP(data.infNFOP)
    }, valid);
  }

  private getInfNFOP: FormGroupGetterFn = (data, valid?) => {
    return this.fb.group({
      innnfo: [data.innnfo, this.valid({ isReq: true })],
      kppnfo: [data.kppnfo, this.valid({ isReq: true })],
      naimNFO: [data.naimNFO, this.valid({ isReq: true })],
      ogrnnfo: [data.ogrnnfo, this.valid({ isReq: true })],
      priznakFlNFO: [data.priznakFlNFO, this.valid({ isReq: true })],
    }, valid);
  }

  /*--------------COMMON------------*/
  getInfFilial: FormGroupGetterFn = (data, valid?) => {
    return this.fbGroup(data, {
      bikfl: [data?.bikfl ?? '', this.valid( { min: 9, max: 9 })],
      nomFl: [data?.nomFl, this.valid({ isReq: true, max: 4 })],
      okatofl: [data?.okatofl, this.valid({ isReq: false, min: 2, max: 2 })],
      regNomKO: [data?.regNomKO, this.valid({ isReq: true, max: 4 })],
    }, valid);
  }

  getUchastnik: FormGroupGetterFn = (data, valid?) => {
    return this.fbGroup(data, {
      kodUchastnika: [data?.kodUchastnika, this.valid({ isReq: true, max: 2 })],
      priznUchastnika: [data?.priznUchastnika, this.valid({ isReq: true, max: 1 })],
      statusUchastnika: [data?.statusUchastnika, this.valid({ isReq: true, max: 1 })],
      tipUchastnika: [data?.tipUchastnika, this.valid({ isReq: true, max: 1 })],
      svedeniiaKO: this.getSvedeniiaKO(data?.svedeniiaKO),
      svedFLIP: this.getSvedFLIP(data?.svedFLIP, null, null, data),
      svedIuL: this.getSvedIuL(data?.svedIuL),
      svedINBOIuL: this.getSvedINBOIuL(data?.svedINBOIuL)
    }, valid);
  }

  getUchastnikFLIP: FormGroupGetterFn = (data, valid?, documentType?, otherParams?) => {
    otherParams = { ...otherParams, flip: 'FLIP' };

    return this.fbGroup(data, {
      benefitsiarFLIP: this.fbArray(this.getBenefitsiar, data?.benefitsiarFLIP),
      identifikatsiiaFL: [data?.identifikatsiiaFL, this.valid({ max: 1 })],
      svedFLIP: this.getSvedFLIP(data?.svedFLIP, [], documentType, otherParams)
    }, valid);
  }

  getUchastnikINBOIuL: FormGroupGetterFn = (data, valid, documentType) => {
    return this.fbGroup(data, {
      benefitsiarINBOIuL: this.fbArray(this.getBenefitsiar, data?.benefitsiarINBOIuL),
      priznakIdentifikatsiiaINBOIuL: [data?.priznakIdentifikatsiiaINBOIuL,
        this.valid({isReq: documentType === DocumentTypeEnum.SUSPICIOUS_ACTIVITY})],
      svedINBOIuL: this.getSvedINBOIuL(data?.svedINBOIuL)
    }, valid);
  }

  getUchastnikIuL: FormGroupGetterFn = (data, valid, documentType, otherParams) => {
    return this.fbGroup(data, {
      benefitsiarIuL: this.fbArray(this.getBenefitsiar, data?.benefitsiarIuL),
      priznakIdentifikatsiiaIuL: [data?.priznakIdentifikatsiiaIuL,
        this.valid({isReq: documentType === DocumentTypeEnum.SUSPICIOUS_ACTIVITY})],
      svedEIO: this.fbArray(this.getSvedEIO, data?.svedEIO),
      svedIuL: this.getSvedIuL(data?.svedIuL, null, null, otherParams?.priznUchastnika)
    }, valid);
  }

  getBenefitsiar: FormGroupGetterFn = (data, valid?) => {
    const condition = data?.identBenefitsiar === '11' || data?.identBenefitsiar === '21';
    return this.fbGroup(data, {
      flbenefitsiar: this.getSvedFLIP(data?.flbenefitsiar),
      identBenefitsiar: [data?.identBenefitsiar, this.valid({ isReq: true, max: 2 })],
      priznBenefitsiar: [condition ? (data?.priznBenefitsiar ?? '0') : null, this.valid({ isReq: condition ?  true : false})]
    }, valid);
  }

  getSvedEIO: FormGroupGetterFn = (data, valid?, documentType?, otherParams?) => {
    otherParams = { ...otherParams, tipEIO: data?.tipEIO };
    return this.fbGroup(data, {
      fleio: this.getSvedFLIP(data?.fleio, [], documentType, otherParams),
      iuLEIO: this.getSvedIuL(data?.iuLEIO),
      priznEIO: [data?.priznEIO ?? 0, this.valid({ max: 1 })],
      tipEIO: [data?.tipEIO, this.valid({ isReq: true, max: 1 })]
    }, valid);
  }

  getSvedFLIP: FormGroupGetterFn = (data, valid?, documentType?, otherParams?) => {

    const group = this.fbGroup(data, {
      adrReg: this.getAddress(data?.adrReg),
      dataRozhdeniia: [data?.dataRozhdeniia, this.valid({ isDate: true })], // req?
      dokUdLichn: [data?.dokUdLichn, this.valid({ isReq: true, max: 1 })],
      fioflip: this.getFio(data?.fioflip),
      innflip: [data?.innflip ?? '', this.valid({ max: 12 , min: 12})],
      kodOKSM: [data?.kodOKSM, this.valid({ max: 3 })],
      ogrnip: [data?.ogrnip, this.valid({ max: 15, min: 15 })],
      polisNomer: [data?.polisNomer, this.valid({isPolicyOMC9or16: true})],
      priznakPubLitso: [data?.priznakPubLitso, this.valid({ max: 1 })],
      regnNomer: [data?.regnNomer, this.valid({ max: 250 })],
      snilsfl: [data?.snilsfl, this.valid({ max: 14, min: 14, pattern: '[0-9]{3}-[0-9]{3}-[0-9]{3}(-| )[0-9]{2}'})],
      tipFLChastnaiaPraktika: [data?.tipFLChastnaiaPraktika, this.valid({ max: 1 })],
      svedDokPreb: this.getSvedDokPreb(data?.svedDokPreb, null, documentType),
      svedDokUdLichn: this.getSvedDokUdLichn(data?.svedDokUdLichn, null, documentType),
      telefonNomer: this.fbArray(this.fbControl, data?.telefonNomer, this.valid('telefonNomer')),
    }, valid);
    // проверка на существование значения в otherParams по ключу 0
    if (otherParams?.tipUchastnika === '2' || otherParams?.tipKlienta === '2') {
      group.get('tipFLChastnaiaPraktika').disable();
      group.get('regnNomer').disable();
    }
    if (otherParams?.tipUchastnika === '3' || otherParams?.tipKlienta === '3') {
      group.get('tipFLChastnaiaPraktika').disable();
      group.get('regnNomer').disable();
    }
    return group;
  }

  getSvedIuL: FormGroupGetterFn = (data, valid?, documentType?, otherParams?) => {
    return this.fbGroup(data, {
      adrIuL: this.getAddress(data?.adrIuL),
      adrRegIuL: this.getAddress(data?.adrRegIuL, valid, documentType),
      dataRegIuL: [data?.dataRegIuL, this.valid({ isDate: true })],
      inniuL: [data?.inniuL, this.valid({ isInnKio5or10: true })],
      kppiuL: [data?.kppiuL, this.valid({ max: 9, min: 9 })],
      naimIuL: [data?.naimIuL, this.valid({ isReq: true, max: 1000 })],
      ogrniuL: [data?.ogrniuL, this.valid({ max: 500 })],
      priznakFlIuL: [data?.priznakFlIuL ?? 0, this.valid({ max: 1 })],
    }, valid);
  }

  getSvedINBOIuL: FormGroupGetterFn = (data, valid?) => {
    return this.fbGroup(data, {
      polnoeNaimINBOIuL: [data?.polnoeNaimINBOIuL, this.valid({ isReq: true, max: 500 })],
      priznakOrgFormaINBOIuL: [data?.priznakOrgFormaINBOIuL, this.valid({ isReq: true, max: 1 })],
      regINBOIuL: this.fbArray(this.getRegINBOIuL, data?.regINBOIuL),
      uchreditel: this.fbArray(this.getUchreditel, data?.uchreditel),
      mestoDeiatelnostINBOIuL: this.getAddress(data?.mestoDeiatelnostINBOIuL),
    }, valid);
  }

  getRegINBOIuL: FormGroupGetterFn = (data, valid?) => {
    return this.fbGroup(data, {
      kodINBOIuL: [data?.kodINBOIuL, this.valid({ max: 50 })],
      nomerINBOIuL: [data?.nomerINBOIuL, this.valid({ max: 50 })]
    }, valid);
  }

  getUchreditel: FormGroupGetterFn = (data, valid?) => {
    return this.fbGroup(data, {
      adrUchreditel: this.getAddress(data?.adrUchreditel),
      naimUchreditel: this.getNaimUchreditel(data?.naimUchreditel),
    }, valid);
  }

  getAddress: FormGroupGetterFn = (data, valid?, documentType?) => {
    const form = this.fbGroup(data, {
      adresStroka: [data?.adresStroka, this.valid({max: 500})],
      dom: [data?.dom, this.valid({ max: 60 })],
      indeks: [data?.indeks, this.valid({ max: 30 })],
      kodOKSM: [data?.kodOKSM, this.valid({ max: 3, isReq: true })],
      kodSubieektaPoOKATO: [data?.kodSubieektaPoOKATO, this.valid({ min: 2, max: 2 })],
      korp: [data?.korp, this.valid({ max: 50 })],
      of: [data?.of, this.valid({ max: 100 })],
      punkt: [data?.punkt, this.valid({ max: 250, isReq: true })],
      rajon: [data?.rajon, this.valid({ max: 250 })],
      ulitsa: [data?.ulitsa, this.valid({ max: 250 })]
    }, valid);

    const controlsName = {
      firstArrayControlsNames: ['dom', 'indeks', 'kodOKSM', 'kodSubieektaPoOKATO', 'korp', 'of', 'punkt', 'ulitsa', 'rajon'],
      secondArrayControlsNames: ['adresStroka']
    }

    this._disableFormControls(form, !data?.adresStroka, controlsName);

    if (documentType && documentType === DocumentTypeEnum.SD_KO_SECURITIES) {
      form.get('kodOKSM').disable();
      form.get('indeks').disable();
      form.get('adresStroka').disable();
    }
    return form;
  }

  getFio: FormGroupGetterFn = (data, valid?) => {
    const form = this.fbGroup(data, {
      fam: [data?.fam, this.valid({ isReq: true, max: 100 })],
      fiostroka: [data?.fiostroka, this.valid({ max: 500 })],
      imia: [data?.imia, this.valid({ isReq: true, max: 100 })],
      otch: [data?.otch, this.valid({ max: 100 })],
    }, valid);

    const controlsName = {
      firstArrayControlsNames: ['fam', 'imia', 'otch'],
      secondArrayControlsNames: ['fiostroka']
    }
    this._disableFormControls(form, !data?.fiostroka, controlsName)

    return form;
  }

  protected getSvedeniiaKO: FormGroupGetterFn = (data, valid?) => {
    return this.fbGroup(data, {
      bikko: [data?.bikko, this.valid({ isBIKKO: true })],
      naimKO: [data?.naimKO, this.valid({ isReq: true, max: 250 })],
      nomerScheta: [data?.nomerScheta, this.valid({ isReq: true, max: 40 })]
    }, valid);
  }

  public getSvedDokPreb: FormGroupGetterFn = (data, valid?, documentType?) => {
    const vidDokKods = ['28', '35', '99'];

    return this.fbGroup(data, {
      inoeNaimenovanieDok: [data?.inoeNaimenovanieDok, this.valid({isReq: vidDokKods.includes(data?.vidDokKod) ? true : false, max: 500 })],
      nachPravPreb: [data?.nachPravPreb, this.valid({ isReq: false, isDate: true })],
      nomDokPreb: [(documentType === DocumentTypeEnum.SUSPICIOUS_ACTIVITY) ? data?.nomDokPreb ?? '' : data?.nomDokPreb, this.valid({ max: 50 })],
      okonchPravPreb: [data?.okonchPravPreb, this.valid({ isReq: false, isDate: true })],
      seriiaDokPreb: [(documentType === DocumentTypeEnum.SUSPICIOUS_ACTIVITY) ? data?.seriiaDokPreb ?? '' : data?.seriiaDokPreb, this.valid({ max: 50 })],
      vidDokKod: [data?.vidDokKod, this.valid({ isReq: true, max: 2 })]
    }, valid);
  }

  public getSvedDokUdLichn: FormGroupGetterFn = (data, valid?, documentType?) => {
    const vidDokKods = ['28', '35', '99'];

    const is5392 = this.validationSrv?.is5392Subject$?.getValue();
    const isp407Answer = this.validationSrv?.isP407answerSubject$?.getValue();

    return this.fbGroup(data, {
      datVydachiDok: [data?.datVydachiDok, this.valid({ isReq: is5392 || isp407Answer, isDate: true })],
      inoeNaimenovanieDok: [data?.inoeNaimenovanieDok, this.valid({ isReq: vidDokKods.includes(data?.vidDokKod) ? true : false, max: 500 })],
      kemVydanDok: [data?.kemVydanDok, this.valid({ ...(is5392 && {isReq: true}), max: 500 })],
      kodPodr: [data?.kodPodr, this.valid({ max: 50 })],
      nomDok: [(documentType === DocumentTypeEnum.SUSPICIOUS_ACTIVITY) ? data?.nomDok ?? '' : data?.nomDok, this.valid({ max: 50 })],
      seriiaDok: [data?.seriiaDok, this.valid({ max: 50 })],
      vidDokKod: [(documentType === DocumentTypeEnum.SUSPICIOUS_ACTIVITY) ? data?.vidDokKod ?? '' : data?.vidDokKod, this.valid({ isReq: true, max: 2 })],
    }, valid);
  }

  getNaimUchreditel: FormGroupGetterFn = (data, valid?) => {
    return this.fbGroup(data, {
      fizLitso: this.getFio(data?.fizLitso),
      iurLitso: [data?.iurLitso, this.valid({ max: 1000 })],
    }, valid);
  }

  getSvedTsP: FormGroupGetterFn = (data, valid?) => {
    return this.fb.group({
      vidTsP: [data?.vidTsP, this.valid({isReq: true})],
      identifikatorTsP: [data?.identifikatorTsP, this.valid({isReq: true})],
      kodValTsP: [data?.kodValTsP],
      stoimostTsP: [data?.stoimostTsP, this.valid({pattern: /^(?!0\d)\d{1,20}\.\d{2}$/})],
      kolTsP: [data?.kolTsP, this.valid({isReq: true})],
      emitent: this.getEmitent(data?.emitent),
      kommentTsP: [data?.kommentTsP, this.valid({})],
    })
  }

  getEmitent: FormGroupGetterFn = (data, valid?) => {
    return this.fb.group({
      priznEmitentTsP: [data?.priznEmitentTsP, this.valid({isReq: true})],
      tipEmitentTsP: [data?.tipEmitentTsP, this.valid({isReq: true})],
      naimEmitentTsP: this.getNaimEmitentTsP(data?.naimEmitentTsP, null, null, data?.tipEmitentTsP),
      innemitentTsP: [data?.innemitentTsP, this.valid({isINN: 'INN_12_10_5'})],
      ogrnemitentTsP: [data?.ogrnemitentTsP, this.valid({isReq: true})],
    })
  }

  getNaimEmitentTsP: FormGroupGetterFn = (data, valid?, documentType?, tipEmitentTsP?) => {
    const group = this.fb.group({
      iurLitso: [data?.iurLitso, this.valid({isReq: true})],
      fizLitso: this.getFio(data?.fizLitso, this.valid({isReq: true})),
    });

    if (tipEmitentTsP != '1') { group.get('iurLitso').disable()};
    if (tipEmitentTsP != '3') { group.get('fizLitso').disable()};

    return group;
  }

   /*--------------Strateg655------------*/
   getInitialDocumentFormStrateg655: FormGroupGetterFn = (data, valid?, docType?) => {
    return this.fb.group({
      informChast: this.getInformChastStrateg655(data?.informChast, valid, docType),
      sluzhChast: this.getSluzhChastStrateg655(data?.sluzhChast)
    }, valid);
  }

  getSluzhChastStrateg655: FormGroupGetterFn = (data, valid?) => {
    return this.fb.group({
      dataSoob: [data?.dataSoob, this.valid({ isReq: true, isDate: true })],
      fioupolnSotrudn: this.getFio(data?.fioupolnSotrudn),
      telUpolnSotrudn: [data?.telUpolnSotrudn, this.valid({ isReq: true, max: 250 })],
      tipInf: [data?.tipInf, this.valid({ isReq: true, max: 6 })],
      upolnSotrudn: [data?.upolnSotrudn, this.valid({ isReq: true, max: 250 })],
      versProg: [data?.versProg, this.valid({ isReq: true, max: 50 })],
      versForm: [data?.versForm, this.valid({ isReq: true, max: 10 })],
      bikkonaprv: [data?.bikkonaprv, this.valid({ isReq: true, max: 9 })],
    }, valid);
  }

  private getInformChastStrateg655: FormGroupGetterFn = (data, valid?, docType?) => {
    return this.fb.group({
      infObshchestvo: this.fbArray(this.infObshchestvo, data?.infObshchestvo, null, null, docType),
      svedKO: this.svedKO(data.svedKO, valid),
    }, valid);
  }

  svedKO: FormGroupGetterFn = (data, valid?) => {
    return this.fbGroup(data, {
      naimKO: [data?.naimKO, this.valid( { isReq: true, min: 1, max: 250 })],
      nomFl: [data?.nomFl, this.valid({ min: 1, max: 4 })],
      bikko: [data?.bikko, this.valid({ min: 9, max: 9 })],
      regNomKO: [data?.regNomKO, this.valid({ isReq: true, min: 1, max: 4 })],
    }, valid);
  }

  infObshchestvo: FormGroupGetterFn = (data, valid?, documentType?) => {
    return this.fb.group({
      adrObshch: this.getAddress(data?.adrObshch, null, documentType),
      komment: [data.komment, this.valid({ max: 2000, min: 1 })],
      svedObshch: this.svedObshch(data?.svedObshch),
      infEmitent: this.fbArray(this.infEmitent, data?.infEmitent),
    }, valid);
  }

  svedObshch: FormGroupGetterFn = (data, valid?) => {
    return this.fb.group({
      naimObshch: [data?.naimObshch, this.valid({ isReq: true, max: 2000, min: 1, })],
      ogrnobshch: [data?.ogrnobshch, this.valid({ max: 13, min: 13, isReq: true })],
      innobshch: [data?.innobshch, this.valid({ isReq: true, max: 10, min: 10 })],
      kppobshch: [data?.kppobshch, this.valid({ isReq: true, max: 9, min: 9 })],
    });
;
  }

  infEmitent: FormGroupGetterFn = (data, valid?, documentType?) => {
    return this.fb.group({
      identifInfKO: [data?.identifInfKO, this.valid({ isReq: true })],
      priznakInf: [data?.priznakInf, this.valid({ isReq: true })],
      kodSostTsenBumagi: [data?.kodSostTsenBumagi, this.valid({ isReq: true })],
      dataPriobreteniia: [data?.dataPriobreteniia, this.valid({ isReq: true })],
      tsenBumagiSved: this.tsenBumagiSved(data?.tsenBumagiSved)
    });
  }

  tsenBumagiSved: FormGroupGetterFn = (data, valid) => {
    return this.fb.group({
      tipEmitent: [data?.tipEmitent, this.valid({ isReq: true })],
      naimEmitent: [data?.naimEmitent, this.valid({ isReq: true, min: 1, max: 2000 })],
      kodEmitent: [data?.kodEmitent],
      infTsenBumag: this.fbArray(this.infTsenBumag, data?.infTsenBumag),
    });
  }

  infTsenBumag: FormGroupGetterFn = (data, valid) => {
    return this.fb.group({
      kodTsenBumag: data?.kodTsenBumag,
      kodVal: data?.kodVal,
      kolTsennykhBumag: data?.kolTsennykhBumag,
      kodValTsena: data?.kodValTsena,
      tsenaPriobreteniia: data?.tsenaPriobreteniia,
      tsenaRubPriobreteniia: data?.tsenaRubPriobreteniia,
      nomGosRegTsenBumag: this.fbArray(this.fbControl, data?.nomGosRegTsenBumag, this.valid('nomGosRegTsenBumag'), this.valid({ isReq: true }))
    });
  }

  nomGosRegTsenBumag: FormGroupGetterFn = (data, valid) => {
    return this.fb.group({
      nomGosRegTsenBumag: data
    });
  }
}
