import { Component, OnInit } from '@angular/core';
import {DocumentComponentBaseSub} from "@amlDocument/base";
import {ITableItem} from "@amlCore/components";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {DocumentBaseService} from "@amlDocument/services";
import {DocumentTypeEnum} from "../../../../../../../../documentForm";
import {DocumentFmV1Service} from "../../../../../../../service/document-fm-v1.service";

@Component({
  selector: 'app-view-osnov-sov-oper-v1',
  templateUrl: './view-osnov-sov-oper-v1.component.html',
  styleUrls: ['../../../../../../../../documentForm/base/documentForm.component.scss']
})
export class ViewOsnovSovOperV1Component extends DocumentComponentBaseSub implements ITableItem, OnInit {
  formGroupApp: FormGroup;
  documentData: any;
  titlePanel = `Основание совершения операции`;
  titleNaimDoc = 'Наименование документа';
  currentGetForm = (param) => this.docSrv.getOsnovanieOpV1(param, null, this.documentType);

  constructor(private readonly docSrv: DocumentFmV1Service) {
    super();
  }

  ngOnInit(): void {
  }

  isValidItem(): boolean {
    return this.formGroupApp.valid;
  }

  onTextInput(e) {
    const textarea = e.target;
    textarea.style.height = '';
    textarea.style.height = Math.min(textarea.scrollHeight + 2, 200) + "px";
  }

  get isNaimDok() {
    return [DocumentTypeEnum.OPERATIONS_1_0,
      DocumentTypeEnum.REFUSE_OPERATION_1_1, DocumentTypeEnum.REFUSE_OPERATION_1_2].includes(this.documentType);
  }
}
