import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {distinctUntilChanged, takeUntil} from "rxjs/operators";
import {DocumentComponentBaseSub, DocumentTypeEnum, OPERATIONS_1_X, REFUSE_OPERATION_1_X} from "../../../../../../../../documentForm";
import {ITableItem} from "@amlCore/components";
import {DocumentFmV1Service} from "../../../../../../../service/document-fm-v1.service";

@Component({
  selector: 'app-view-info-credit-org-v1',
  templateUrl: './view-info-credit-org-v1.component.html',
  styleUrls: ['../../../../../../../../documentForm/base/documentForm.component.scss']
})
export class ViewInfoCreditOrgV1Component extends DocumentComponentBaseSub implements ITableItem , OnInit {
  @Input() formGroupApp: FormGroup;
  @Input() documentData: any;
  @Input() titlePanel = `Информация о КО (филиале КО)`;

  constructor(readonly docSrv: DocumentFmV1Service) {
    super();
  }

  ngOnInit(): void {
    this.formGroup('infFilial')?.get('bikfl')?.valueChanges?.pipe(takeUntil(this.componentDestroyed$))?.subscribe(value => {
      const priznakPredstavSved = this.formGroup('priznakPredstavSved')?.value;

      if (!value && value !== '' && priznakPredstavSved == 1) {
        this.formGroup('infFilial')?.get('bikfl')?.setValue('');
      }
    })
    this.handleInfFilialDisable( this.formGroup('priznakPredstavSved')?.value == 1 && !this.isReadOnly)

    this.formGroup('priznakPredstavSved')?.valueChanges.pipe(distinctUntilChanged(), takeUntil(this.componentDestroyed$)).subscribe((value) =>  this.handleInfFilialDisable(value == 1))

    if (this.index === null) this.formGroupApp.markAsDirty();
  }

  currentGetForm = (data) => {
    return this.isOperations
      ? this.docSrv.getOperationDetailsV1.call(this.docSrv, data, null, this.documentType)
          : this.docSrv.getSvedPredstavV1.call(this.docSrv, data, this.documentType);
  }
  isValidItem(): boolean {
    return !this.formGroup('infFilial').invalid;
  }

  handleInfFilialDisable(condition) {
    condition ? this.formGroup('infFilial')?.enable() : (this.formGroup('infFilial')?.disable());
  }

  get isOperations(){
    return OPERATIONS_1_X.includes(this.documentType);
  }

  get isSuspiciousActivity(){
    return this.documentType === DocumentTypeEnum.SUSPICIOUS_ACTIVITY;
  }

  get isRefuseOperation1x() {
    return REFUSE_OPERATION_1_X.includes(this.documentType);
  }
}
