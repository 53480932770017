import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormArray, FormGroup} from "@angular/forms";
import {DocumentComponentBaseSub} from "@amlDocument/base";
import {DocumentConfigService, DocumentTypeEnum, getDocumentConfig, OPERATIONS_1_X, REFUSE_OPERATION_1_X, STRATEG655_3732} from "../../../enums";
import * as selectItems from "../../../../../../json/selectItems.json";
import {pairwise, startWith, takeUntil} from "rxjs/operators";

@Component({
    selector: 'app-arm-doc-view-service-part[formGroupApp][documentData][isReadOnly][submitted]',
    templateUrl: './service-part.component.html',
    styleUrls: ["../../../base/documentForm.component.scss"],
})
export class ServicePartComponent extends DocumentComponentBaseSub implements OnInit {
    @Input() formGroupApp: FormGroup;
    @Input() documentData: any;
    @Input() documentType: DocumentTypeEnum;
    @Output() changeVersiiaFormata = new EventEmitter<string>();
    configuration: DocumentConfigService = null;
    selectTipInf = false;
    versFormat = selectItems.versiiaFormata;
    is5392 = false;
    isStrateg655 = false;

    ngOnInit(): void {
        if (OPERATIONS_1_X.includes(this.documentType)) {
            this.versFormat = selectItems.operations1xVersiiaFormata;
        }
        if (REFUSE_OPERATION_1_X.includes(this.documentType)) {
            this.versFormat = selectItems.refuseOperation1xVersiiaFormata;
        }
        if (STRATEG655_3732.includes(this.documentType)) {
          this.versFormat = selectItems.versiiaFormata3732;
        }
        if (this.documentType === DocumentTypeEnum.SD_KO_SECURITIES) {
          this.isStrateg655 = true;
        }
        if (this.documentType) {
            this.configuration = getDocumentConfig(this.documentType);
            if (this.configuration.tipInf) {
                this.selectTipInf = true;
            }
        }
        if (this.documentType === DocumentTypeEnum.OES_OTKAZ_GOZ) {
          this.is5392 = true;
        }
        const versiiaFormata = this.formGroupApp?.get('versiiaFormata');
        versiiaFormata?.valueChanges
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe((value) => {
            if ((value === '2.0' || value === '2.1') && !this.isReadOnly) {
                this.callToggleWithDefineType(value);
            }
            this.changeVersiiaFormata?.emit(value);
        });
        if (versiiaFormata?.value && !this.isReadOnly) {
            this.callToggleWithDefineType(versiiaFormata.value);
        }
    }

    toggleSvedTsP(mainBlockName: string, childBlockName: string, isNeedActive: boolean): void {
        const informChast = this.formGroupApp?.parent.get('informChast');
        const mainBlock = informChast.get(mainBlockName) as FormArray;
        mainBlock?.controls.forEach((mainBlockControl: FormGroup) => {
            const childBlock = mainBlockControl.get(childBlockName) as FormArray;
            childBlock?.controls.forEach((childBlockControl: FormGroup) => {
               if (isNeedActive) {
                   const svedTsP = childBlockControl?.get('svedTsP') as FormArray;
                   svedTsP.enable();
                   svedTsP?.controls.forEach(svedTsPControl => {
                       const emitent = svedTsPControl?.get('emitent');
                       const tipEmitentTsP = emitent?.get('tipEmitentTsP');
                       const naimEmitentTsP = emitent?.get('naimEmitentTsP');
                       if (tipEmitentTsP?.value === '3') {
                           naimEmitentTsP?.get('fizLitso').enable();
                           naimEmitentTsP?.get('iurLitso').disable();
                       } else if (tipEmitentTsP?.value === '1') {
                           naimEmitentTsP?.get('fizLitso').disable();
                           naimEmitentTsP?.get('iurLitso').enable();
                       }
                   });
               } else {
                   childBlockControl?.get('svedTsP').disable();
               }
            });
        });
    }

    callToggleWithDefineType(versiiaFormata: string) {
        const documentTypeMappings = {
            [DocumentTypeEnum.OPERATIONS]: ['svedKO', 'operatsiia'],
            [DocumentTypeEnum.REFUSE_OPERATION]: ['svedPredstav', 'svedOtkaz'],
            [DocumentTypeEnum.FREEZING_FUNDS]: ['svedKO', 'mery'],
        };

        const mapping = documentTypeMappings[this.documentType];

        if (mapping) {
            this.toggleSvedTsP(mapping[0], mapping[1], versiiaFormata === '2.1');
        }
    }

    get isRefuseOperation1x() {
        return [DocumentTypeEnum.REFUSE_OPERATION_1_1, DocumentTypeEnum.REFUSE_OPERATION_1_2].includes(this.documentType)
    }
}
